<template>
  <div v-if="isPermission">
    <CCard class="shadow-sm m-2">
      <CCardBody>
        <CRow>
          <CCol sm="1" col="2" >
            <img src="/img/icon/store.png" style="width: 32px; height:32px;"/>
          </CCol>
          <CCol sm="9" col="7">
            <select class="custom-select text-dark" v-model="shop" disabled>
              <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                {{ shop.shopName }} - {{ shop.branchName }}
              </option>
            </select>
            <div v-if="validateShop" class="text-danger">
              {{ validateShop }}
            </div>
          </CCol>
          <div class="col-md-2 col-sm-2 col-3 text-right"
            v-if="loadingButton == true">
            <CButton block color="light" @click="goback()">
              <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
            </CButton>
          </div>
          <div class="col-md-2 col-sm-2 text-right" v-else>
            <CButton block color="success" disabled>
              <CSpinner color="white" size="sm" />{{ $t('loading') }}
            </CButton>
          </div>
        </CRow>
      </CCardBody>
    </CCard>
    <CCol md="12" lg="12" class="mt-4">
      <CCard class="shadow-sm">
        <CCardBody>
          <CAlert color="success" v-if="isSuccess === true">
            {{ $t('createProductSuccess') }}
          </CAlert>
          <div class="row">
            <div class="col-md-10 col-sm-9 col-9">
              <h4 class="font-weight-normal">
                {{ $t('productTypeBOM') }} ({{ countBom }})
              </h4>
              <h5 class="font-weight-normal">
                <span class="h5 font-weight-normal">
                  {{ $t('productName') }} : {{ SKUName }}
                </span>
              </h5>
            </div>
            <div class="col-md-2 col-sm-3 col-3 text-right" v-if="isEditData">
              <CButton block color="success font-weight-normal" @click="addProductBOM()">
                {{ $t('editBOM') }}
              </CButton>
            </div>
          </div>
          <hr />

          <CRow>
            <CCol sm="12" lg="12" md="12">
            <div style="overflow-x: auto">
              <table class="table table-hover border"
                style="width: 100%;white-space:nowrap">
                <thead>
                  <th class="font-weight-normal text-dark" style="width:7%">{{ $t('img') }}</th>
                  <th class="font-weight-normal text-dark">{{ $t('pluCode') }}</th>
                  <th class="font-weight-normal text-dark" style="width:60%">{{ $t('productName') }}</th>
                  <th class="font-weight-normal text-dark text-right" style="width:10%">{{ $t('cost') }}</th>
                  <th style="width:10%" class="text-right font-weight-normal text-dark">
                    {{ $t('SKURatio') }}
                  </th>
                </thead>
                <tbody v-if="loadingButton == true">
                  <tr v-for="(item, index) in this.BOM"
                    :key="index"
                    hover
                    border
                    striped>
                    <td>
                      <div v-if="item.remoteImagePath !== undefined &&
                              item.remoteImagePath !== '' &&
                              item.remoteImagePath !== null "
                          class="square-box"
                          :style="{
                            'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                            'background-size': 'cover',
                            'background-position': 'center center',
                            'width': 'auto',
                            'height': '40px',
                            'border-radius': '3px',
                            'aspect-ratio': '1.3'
                          }"
                        ></div>
                        <div
                          v-else
                          class="square-box"
                          :style="{
                            backgroundColor: item.colori,
                            width: 'auto',
                            'background-origin': 'content-box',
                            'border-radius': '3px',
                            'height': '40px',
                            'aspect-ratio': '1.3'
                          }"
                        ></div>
                    </td>
                    <td class="font-weight-normal text-dark">{{ item.ProductPLU.PLUCode }}</td>
                    <td class="font-weight-normal text-dark">
                      {{ item.ProductPLU.name }} ({{
                        item.unit
                      }})
                    </td>
                    <td class="text-right font-weight-normal text-dark">
                      {{ item.itemcost }}
                    </td>
                    <td class="text-right font-weight-normal text-dark">
                      {{ formatNumber(item.quantity) }}
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              <div
                v-if="loadingButton == false"
                class="text-center"
                width="100%"
              >
                <button
                  class="btn"
                  type="button"
                  disabled
                  style="color: darkcyan"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {{ $t('loading') }}
                </button>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>
<script>
import util from '@/util/util'
import { mapGetters } from 'vuex'
import local from '@/services/local'
import permis from '@/util/permission'

export default {
  components: {},
  data() {
    return {
      isSuccess: false,
      SKUName: '',
      addBom: false,
      keyword: '',
      data: [],
      dataSKUbytype: [],
      itemObjectId: [],
      validateShop: false,
      shop: this.$store.getters.shopObjectId,
      addlist: [],
      BOM: [],
      quantity: 1,
      countAddList: 0,
      loadingButton: true,
      countBom: 0,
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      let path = '/product'
      return permis.findPermissionRead('product', path)
    },
    isEditData() {
      let path = '/product'
      return permis.findPermissionEdit('product', path)
    },
    isDeleteData() {
      let path = '/product'
      return permis.findPermissionRemove('product', path)
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    fields() {
      return [{ key: 'name', label: 'ชื่อสินค้า' }]
    },
    items() {
      let detail = []
      let data = this.dataSKUbytype

      for (let i = 0; i < data.length; i++) {
        detail.push({
          remoteImagePath: data[i].remoteImagePath,
          SKUName: data[i].SKUName,
          unit: data[i].unit,
          id: data[i].id,
          objectId: data[i].objectId,
          productPLU: data[i].productPLU,
          colori: util.generateColor(data[i].indexColor),
        })
      }
      return detail
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
  },
  created() {
    this.getSKUbyType()
    this.getBOM()
    this.getProduct()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
  },
  methods: {
    formatNumber(value) {
    if (value % 1 === 0) {
      // เป็นจำนวนเต็ม
      return value.toString();
    } else {
      // มีจุดทศนิยม
      return value.toFixed(4).replace(/\.?0+$/, '');
    }
  },
    getBOM() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let params = {
        skuObjectId: this.$route.params.productSKUObjectId,
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      local({
        url: '/api/v1.0/' + uid + '/productsku/getbom',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        let data = res.data.data
        if (data == null) {
          this.countBom = 0
        } else {
          this.countBom = data.length
        }

        data.forEach((e) => {

          let stdCost = parseFloat(e.ProductSKU.stdCost)
          let quantity = parseFloat(e.quantity)
          let itemcost;
          if (stdCost) {
            itemcost = (stdCost * quantity).toFixed(2);
          } else {
            itemcost = this.$t('nonCost');
          }
          let ProductPLU = e.ProductPLU ? e.ProductPLU : e.productPLU
          let unit = ProductPLU.unit ? ProductPLU.unit : e.unit
          let unitname = unit && unit.name ? unit.name : '-'
          this.BOM.push({
            ProductPLU: ProductPLU,
            id: e.ProductPLU.id,
            name: e.ProductPLU.name,
            objectId: e.ProductPLU.objectId,
            unit: unitname,
            quantity: parseFloat(e.quantity),
            colori: util.generateColor(e.ProductPLU.indexColor),
            remoteImagePath: e.ProductPLU.remoteImagePath,
            itemcost: itemcost
          })
        })
      })
    },
    getSKUbyType() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      this.loadingButton = false
      let params = {
        shopObjectId: shopObjectId,
        skuType: 'P',
        keyword: this.keyword,
        page: 1,
        limit: 50,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      local({
        url: '/api/v1.0/' + uid + '/productsku/getbytype',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.dataSKUbytype = res.data.data
        this.loadingButton = true
      })
    },
    getProduct() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let data = {}
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: '',
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = false
      local({
        url:
          '/api/v1.0/' +
          uid +
          '/ProductSKU/data/' +
          this.$route.params.productSKUObjectId,
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.data = res.data.data
        data = this.data
        this.SKUName = data.document.SKUName
        this.loadingButton = true
      })
    },

    addProductBOM() {
      this.$router.push('add/' + this.$route.params.productSKUObjectId)
    },
    goback() {
      let productSKUObjectId = this.$route.params.productSKUObjectId
      this.$router.push({
        name: 'ProductDetail',
        params: { productSKUObjectId },
        query: {
          sort: this.$route.query.sort,
          page: this.$route.query.page,
          view: this.$route.view,
        },
      })
    },
  },
}
</script>

<style>
.cardadd {
  min-height: 438px;
  max-height: 400px;
  border-color: #3abc98;
}
</style>
